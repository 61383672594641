<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Datepicker</h2>
    </div>
    <div class="intro-y grid grid-cols-12 gap-6 mt-5">
      <div class="col-span-12 lg:col-span-6">
        <!-- BEGIN: Basic Datepicker -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Basic Date Picker</h2>
            <div
              class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
            >
              <div class="mr-3">Show example code</div>
              <input
                data-target="#basic-datepicker"
                class="show-code input input--switch border"
                type="checkbox"
              />
            </div>
          </div>
          <div id="basic-datepicker" class="p-5">
            <div class="preview">
              <Litepicker
                v-model="date"
                :options="{
                  autoApply: false,
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true,
                  },
                }"
                class="input w-56 border block mx-auto"
              />
            </div>
            <div class="source-code hidden">
              <button
                data-target="#copy-basic-datepicker"
                class="copy-code button button--sm border flex items-center text-gray-700 dark:border-dark-5 dark:text-gray-300"
              >
                <FileIcon class="w-4 h-4 mr-2" /> Copy example code
              </button>
              <div class="overflow-y-auto h-64 mt-3">
                <Highlight id="copy-basic-datepicker" class="source-preview">
                  <code
                    class="text-xs p-0 rounded-md html pl-5 pt-8 pb-4 -mb-10 -mt-10"
                  >
                    <textarea>
                      <Litepicker
                        v-model="date"
                        :options="{
                          autoApply: false,
                          showWeekNumbers: true,
                          dropdowns: {
                            minYear: 1990,
                            maxYear: null,
                            months: true,
                            years: true
                          }
                        }"
                        class="input w-56 border block mx-auto"
                      />
                    </textarea>
                  </code>
                </Highlight>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Basic Datepicker -->
        <!-- BEGIN: Input Group -->
        <div class="intro-y box mt-5">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Input Group</h2>
            <div
              class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
            >
              <div class="mr-3">Show example code</div>
              <input
                data-target="#input-group-datepicker"
                class="show-code input input--switch border"
                type="checkbox"
              />
            </div>
          </div>
          <div id="input-group-datepicker" class="p-5">
            <div class="preview">
              <div class="relative w-56 mx-auto">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
                >
                  <CalendarIcon class="w-4 h-4" />
                </div>
                <Litepicker
                  v-model="date"
                  :options="{
                    autoApply: false,
                    showWeekNumbers: true,
                    dropdowns: {
                      minYear: 1990,
                      maxYear: null,
                      months: true,
                      years: true,
                    },
                  }"
                  class="input pl-12 border"
                />
              </div>
            </div>
            <div class="source-code hidden">
              <button
                data-target="#copy-input-group-datepicker"
                class="copy-code button button--sm border flex items-center text-gray-700 dark:border-dark-5 dark:text-gray-300"
              >
                <FileIcon class="w-4 h-4 mr-2" /> Copy example code
              </button>
              <div class="overflow-y-auto h-64 mt-3">
                <Highlight
                  id="copy-input-group-datepicker"
                  class="source-preview"
                >
                  <code
                    class="text-xs p-0 rounded-md html pl-5 pt-8 pb-4 -mb-10 -mt-10"
                  >
                    <textarea>
                      <div class="relative w-56 mx-auto">
                        <div
                          class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
                        >
                          <CalendarIcon class="w-4 h-4" />
                        </div>
                        <Litepicker
                          v-model="date"
                          :options="{
                            autoApply: false,
                            showWeekNumbers: true,
                            dropdowns: {
                              minYear: 1990,
                              maxYear: null,
                              months: true,
                              years: true
                            }
                          }"
                          class="input pl-12 border"
                        />
                      </div>
                    </textarea>
                  </code>
                </Highlight>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input Group -->
      </div>
      <div class="col-span-12 lg:col-span-6">
        <!-- BEGIN: Daterange Picker -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Date Range Picker</h2>
            <div
              class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
            >
              <div class="mr-3">Show example code</div>
              <input
                data-target="#daterangepicker"
                class="show-code input input--switch border"
                type="checkbox"
              />
            </div>
          </div>
          <div id="daterangepicker" class="p-5">
            <div class="preview">
              <Litepicker
                v-model="daterange"
                :options="{
                  autoApply: false,
                  singleMode: false,
                  numberOfColumns: 2,
                  numberOfMonths: 2,
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true,
                  },
                }"
                class="input w-56 border block mx-auto"
              />
            </div>
            <div class="source-code hidden">
              <button
                data-target="#copy-daterangepicker"
                class="copy-code button button--sm border flex items-center text-gray-700 dark:border-dark-5 dark:text-gray-300"
              >
                <FileIcon class="w-4 h-4 mr-2" /> Copy example code
              </button>
              <div class="overflow-y-auto h-64 mt-3">
                <Highlight id="copy-daterangepicker" class="source-preview">
                  <code
                    class="text-xs p-0 rounded-md html pl-5 pt-8 pb-4 -mb-10 -mt-10"
                  >
                    <textarea>
                      <Litepicker
                        v-model="daterange"
                        :options="{
                          autoApply: false,
                          singleMode: false,
                          numberOfColumns: 2,
                          numberOfMonths: 2,
                          showWeekNumbers: true,
                          dropdowns: {
                            minYear: 1990,
                            maxYear: null,
                            months: true,
                            years: true
                          }
                        }"
                        class="input w-56 border block mx-auto"
                      />
                    </textarea>
                  </code>
                </Highlight>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Daterange Picker -->
        <!-- BEGIN: Modal Datepicker -->
        <div class="intro-y box mt-5">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Modal Date Picker</h2>
            <div
              class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
            >
              <div class="mr-3">Show example code</div>
              <input
                data-target="#modal-datepicker"
                class="show-code input input--switch border"
                type="checkbox"
              />
            </div>
          </div>
          <div id="modal-datepicker" class="p-5">
            <div class="preview">
              <div class="text-center">
                <a
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#datepicker-modal-preview"
                  class="button inline-block bg-theme-1 text-white"
                  >Show Modal</a
                >
              </div>
              <div id="datepicker-modal-preview" class="modal">
                <div class="modal__content">
                  <div
                    class="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5"
                  >
                    <h2 class="font-medium text-base mr-auto">
                      Filter by Date
                    </h2>
                    <button
                      class="button border items-center text-gray-700 dark:border-dark-5 dark:text-gray-300 hidden sm:flex"
                    >
                      <FileIcon class="w-4 h-4 mr-2" /> Download Docs
                    </button>
                    <div class="dropdown sm:hidden">
                      <a
                        class="dropdown-toggle w-5 h-5 block"
                        href="javascript:;"
                      >
                        <MoreHorizontalIcon
                          class="w-5 h-5 text-gray-600 dark:text-gray-600"
                        />
                      </a>
                      <div class="dropdown-box w-40">
                        <div
                          class="dropdown-box__content box dark:bg-dark-1 p-2"
                        >
                          <a
                            href="javascript:;"
                            class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                          >
                            <FileIcon class="w-4 h-4 mr-2" />
                            Download Docs
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-5 grid grid-cols-12 gap-4 gap-y-3">
                    <div class="col-span-12 sm:col-span-6">
                      <label>From</label>
                      <Litepicker
                        v-model="date"
                        :options="{
                          autoApply: false,
                          showWeekNumbers: true,
                          dropdowns: {
                            minYear: 1990,
                            maxYear: null,
                            months: true,
                            years: true,
                          },
                        }"
                        class="input w-full border mt-2"
                      />
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <label>To</label>
                      <Litepicker
                        v-model="date"
                        :options="{
                          autoApply: false,
                          showWeekNumbers: true,
                          dropdowns: {
                            minYear: 1990,
                            maxYear: null,
                            months: true,
                            years: true,
                          },
                        }"
                        class="input w-full border mt-2"
                      />
                    </div>
                  </div>
                  <div
                    class="px-5 py-3 text-right border-t border-gray-200 dark:border-dark-5"
                  >
                    <button
                      type="button"
                      data-dismiss="modal"
                      class="button w-20 border text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="button w-20 bg-theme-1 text-white"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="source-code hidden">
              <button
                data-target="#copy-modal-datepicker"
                class="copy-code button button--sm border flex items-center text-gray-700 dark:border-dark-5 dark:text-gray-300"
              >
                <FileIcon class="w-4 h-4 mr-2" /> Copy example code
              </button>
              <div class="overflow-y-auto h-64 mt-3">
                <Highlight id="copy-modal-datepicker" class="source-preview">
                  <code
                    class="text-xs p-0 rounded-md html pl-5 pt-8 pb-4 -mb-10 -mt-10"
                  >
                    <textarea>
                      <div class="text-center">
                        <a
                          href="javascript:;"
                          data-toggle="modal"
                          data-target="#datepicker-modal-preview"
                          class="button inline-block bg-theme-1 text-white"
                          >Show Modal</a
                        >
                      </div>
                      <div id="datepicker-modal-preview" class="modal">
                        <div class="modal__content">
                          <div
                            class="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5"
                          >
                            <h2 class="font-medium text-base mr-auto">
                              Filter by Date
                            </h2>
                            <button
                              class="button border items-center text-gray-700 hidden sm:flex"
                            >
                              <FileIcon class="w-4 h-4 mr-2" />
                              Download Docs
                            </button>
                            <div class="dropdown sm:hidden">
                              <a
                                class="dropdown-toggle w-5 h-5 block"
                                href="javascript:;"
                              >
                                <MoreHorizontalIcon
                                  class="w-5 h-5 text-gray-600 dark:text-gray-600"
                                />
                              </a>
                              <div
                                class="dropdown-box w-40"
                              >
                                <div
                                  class="dropdown-box__content box dark:bg-dark-1 p-2"
                                >
                                  <a
                                    href="javascript:;"
                                    class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                                  >
                                    <FileIcon class="w-4 h-4 mr-2" />
                                    Download Docs
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="p-5 grid grid-cols-12 gap-4 gap-y-3">
                            <div class="col-span-12 sm:col-span-6">
                              <label>From</label>
                              <Litepicker
                                v-model="date"
                                :options="{
                                  autoApply: false,
                                  showWeekNumbers: true,
                                  dropdowns: {
                                    minYear: 1990,
                                    maxYear: null,
                                    months: true,
                                    years: true
                                  }
                                }"
                                class="input w-full border mt-2"
                              />
                            </div>
                            <div class="col-span-12 sm:col-span-6">
                              <label>To</label>
                              <Litepicker
                                v-model="date"
                                :options="{
                                  autoApply: false,
                                  showWeekNumbers: true,
                                  dropdowns: {
                                    minYear: 1990,
                                    maxYear: null,
                                    months: true,
                                    years: true
                                  }
                                }"
                                class="input w-full border mt-2"
                              />
                            </div>
                          </div>
                          <div
                            class="px-5 py-3 text-right border-t border-gray-200 dark:border-dark-5"
                          >
                            <button
                              type="button"
                              data-dismiss="modal"
                              class="button w-20 border text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              class="button w-20 bg-theme-1 text-white"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </textarea>
                  </code>
                </Highlight>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Modal Datepicker -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup () {
    const date = ref('')
    const daterange = ref('')

    return {
      date,
      daterange
    }
  }
})
</script>
